import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Text, useThemeUI } from "theme-ui";
import { AppContext } from "contexts/AppContext";
import getStaticPageProps from "utils/getStaticPageProps";
import usePageData from "hooks/usePageData";
import Seo from "components/Seo";

const custom404 = () => {
  const { setShowHeroSlide } = useContext(AppContext);

  const { data: navigationData } = usePageData("navigation", "/", false);

  useEffect(() => {
    setShowHeroSlide(false);
  }, []);

  const { setCurrentNavLinks, setCurrentSubPage, setCurrentContactNav } =
    useContext(AppContext);
  const { setColorMode } = useThemeUI();

  useEffect(() => {
    const navigationCollection = navigationData?.navigationCollection?.items[0];
    const { contactLinkText, contactLinkUrl } = navigationCollection;
    setCurrentContactNav({ label: contactLinkText, url: contactLinkUrl?.slug });

    const navigationItems =
      navigationData?.navigationCollection?.items[0]?.navigationItemsCollection
        ?.items || [];
    let navLinks = navigationItems.map(({ title, pageLink }) => {
      const navSlug = pageLink?.slug;
      return {
        label: title,
        href: navSlug,
      };
    }, []);
    setCurrentSubPage({
      title: navigationCollection.title,
      slug: navigationCollection.slug,
    });
    setCurrentNavLinks(navLinks);
    switch (navigationCollection?.theme) {
      case "Light":
        setColorMode("light");
        break;
      case "Dark":
        setColorMode("default");
        break;
      default:
        setColorMode("default");
    }
  }, []);

  return (
    <>
      <Wrapper>
        <Text as="h1" variant="heading404">
          404
        </Text>
        <Text variant="text404" as="p">
          Page not found.
        </Text>
      </Wrapper>
      <Seo title="Page not found" />
    </>
  );
};
export default custom404;

const Wrapper = styled(Box)`
  ${({ theme }) =>
    theme.mq({
      height: [
        `calc(100vh - ${theme.navHeights[0]})`,
        `calc(100vh - ${theme.navHeights[1]})`,
      ],
    })};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled(Text)``;

export const getStaticProps = async ({ preview = false }) =>
  await getStaticPageProps("404", null, preview);
